.UserInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  min-height: 48px;
  padding: 0;
  cursor: pointer;
}

.UserDisplayName {
  margin-left: 10px;
}

/* up to md */
@media screen and (max-width: 768px) {
  .UserDisplayName {
    margin-left: 8px;
  }
}
