.EditButton {
  padding-right: 0;
}

.TimelineLgContainer {
  height: calc(100% - 50px);
}

.TimelineLg {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  margin-top: -24px;
  padding-top: 24px;
}
