.Container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 24px 44px;
}

.Footer {
  flex: 1;
  text-align: center;
  padding: 0;
}
