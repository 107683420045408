.Card {
  border-radius: 6px;
}

.HeaderContainer {
  margin-bottom: 4px;
}

.PreTitle {
  margin-right: 8px;
}

.Title {
  display: inline;
  margin: 0;
}
