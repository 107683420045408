.ToolbarHeader {
  margin: 4px 0;
}

.ToolbarDocTitle {
  text-align: center;
  width: 100%;
  margin: 0 !important;
}

.ToolbarHeaderRight {
  text-align: right;
}

/* md and up */
@media screen and (min-width: 768px) {
  .ToolbarHeader {
    margin-top: 0;
    margin-bottom: -6px;
  }
}
