.TemplateInfoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.TemplateInfoIcon {
  width: 20px;
  min-width: 20px;
}

.TemplateInfo {
  margin-bottom: 3px !important;
}
