.ProfileHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  padding-left: 6px;
}

.Avatar {
  margin-right: 16px;
}

.HeaderContent {
  flex: 1;
}

.HeaderContentMain {
  display: flex;
  align-items: center;
}

.HeaderRight {
  flex: 1;
  text-align: right;
}

.HeaderSub {
  margin-top: -8px;
}

.SettingsButton {
  margin-left: 8px;
}

.DataItem textarea {
  margin-top: 4px;
  margin-left: 6px;
  min-width: calc(100vw - 28px);
}

/* md and up */
@media screen and (min-width: 768px) {
  .ProfileHeader {
    margin-top: 0;
    padding-left: 0;
  }

  .DisplayName {
    margin-right: 24px;
  }

  .SettingsButton {
    margin-left: 16px;
  }

  .DataItem {
    margin-left: 0;
  }

  .DataItem textarea {
    margin-left: 12px;
    min-width: 100%;
  }
}
