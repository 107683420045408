.AchievementDescription {
  margin-bottom: 24px !important;
}

/* up to md */
@media screen and (max-width: 768px) {
  .AchievementDetails {
    max-height: calc((100vh / 5) * 2);
    overflow-y: scroll;
    margin: -12px -16px;
    padding: 12px 16px 0px;
  }
}
