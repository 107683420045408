.ListContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 -28px -18px;
}

.List {
  overflow: scroll;
}

.InfiniteScroll {
  padding: 0 28px 28px;
}

.SearchContainer {
  padding: 0 28px;
}

.Loading {
  margin-top: 42px;
}

/* up to md */
@media screen and (max-width: 768px) {
  .List {
    /* padding-bottom: 130px; */
    padding-bottom: 28px;
  }
}
