.Footer {
  display: flex;
  margin-top: -2px;
  margin-bottom: -2px;
}

.AccountBtnContainer {
  flex: 1;
  padding-right: 8px;
}

.HomeBtn {
  width: 32px !important;
}
