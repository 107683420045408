.Container {
  display: flex;
  flex-flow: column;
  height: 100%;
  margin: 0;
}

.Screen {
  height: 100%;
  overflow: hidden;
}
