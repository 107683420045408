.DataLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

/* up to md */
@media screen and (max-width: 768px) {
  .DataLabel {
    margin-bottom: 6px;
  }
}
