.PdfViewerContainer {
  flex: 1;
  display: flex;
  overflow: hidden;
}

.PdfViewerLoading {
  width: 100%;
  text-align: center;
  margin-top: 64px;
}
