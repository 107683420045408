.ThemeLoad {
  background-color: #111;
  color: rgb(23, 125, 220);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding-bottom: 50vh;
  z-index: 9999;
}
