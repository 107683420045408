.Messages {
  margin-top: 6px;
  margin-bottom: 18px;
}

.MessageGroup {
  display: flex;
  flex-direction: column;
}

.MessageGroupIcon {
  margin-right: 4px;
}

.MessageGroupButton {
  margin-top: 10px;
}
