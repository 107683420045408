.Container {
  margin: 16px 0 -14px 28px;
}

.Breadcrumb {
  margin-bottom: 14px;
}

.Icon {
  margin-right: 6px;
}

.Disabled {
  pointer-events: none;
}

/* up to md */
@media screen and (max-width: 768px) {
  .Container {
    margin: 0;
    display: none;
  }
}
