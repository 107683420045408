.DashboardRow {
  height: 100%;
}

.DashboardCol {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.Search {
  margin-bottom: 12px;
}
