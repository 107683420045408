.PageGridCol {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* md and up */
@media screen and (min-width: 768px) {
  .ListTitle {
    margin-bottom: 10px;
  }
}
