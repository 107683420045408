.AvatarInitialsContainer {
  display: flex;
  align-items: center;
  height: 40px;
}

.AvatarInitials {
  font-weight: 600 !important;
  margin-bottom: 0 !important;
}
