.AchievementTitleContainer {
  margin-top: -4px;
  margin-bottom: 12px;
}

.AchievementTitle {
  font-weight: 500;
}

/* up to md */
@media screen and (max-width: 768px) {
  .AchievementTitleContainer {
    margin-right: -8px;
  }
}
